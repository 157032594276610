const photoData = [
  {
    id: 1,
    img: "https://i.postimg.cc/mgX2qtWV/theme-logo.jpg",
    text: "Forever enTWINed",
  },
  {
    id: 2,
    img: "https://i.postimg.cc/G3xRVHkY/lynne.jpg",
    text: "Forever enTWINed CO-Owner",
  },
  {
    id: 3,
    img: "https://i.postimg.cc/13fZ8Bbc/nessa.jpg",
    text: "Forever enTWINed CO-Owner",
  },
  {
    id: 4,
    img: "https://i.postimg.cc/hj8kk57g/duo-logo.jpg",
    text: "Forever enTWINed Owners",
  },
  // {
  //   id: 5,
  //   img: "https://i.ibb.co/XYKN6sq/DSCI0769.jpg",
  //   text: "Bees Believe",
  // },
  // {
  //   id: 6,
  //   img: "https://i.ibb.co/LNqjMH4/DSCI0868.jpg",
  //   text: "Dandelion's Shine too",
  // },
  // {
  //   id: 7,
  //   img: "https://i.ibb.co/ck0JxPR/DSCI0953.jpg",
  //   text: "From the block",
  // },
  // {
  //   id: 8,
  //   img: "https://i.ibb.co/6t1zwcJ/DSCI0801.jpg",
  //   text: "yellow flower",
  // },
  // {
  //   id: 9,
  //   img: "https://i.ibb.co/2kNbkWP/relax.jpg",
  //   text: "wood relax",
  // },
];

export default photoData;
